import type { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '50px 1fr 15px',
  borderRadius: '10px',
  backgroundColor: '#F7F7F7',
  padding: '3px',
  gap: '8px'
};

export const trigger: CSSProperties = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: '#414141',
  fontSize: '13px',
  lineHeight: '18px',
  fontWeight: 600
};

export const icon: CSSProperties = { cursor: 'pointer', color: '#888888' };
