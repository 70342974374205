import { createAsyncThunk } from '@reduxjs/toolkit';

import type { AiFunction } from '@eeedo/types';

import AiFunctionsApi from '../api/aiFunctionsApi';

// Fetch AI functions
export const fetchAiFunctions = createAsyncThunk<AiFunction[], void, { rejectValue: Error }>(
  'aiFunctions/fetchAiFunctions',
  async (_, { rejectWithValue }) => {
    try {
      // Make sure AiFunctionsApi.fetch() returns the array of AiFunction
      const response = await AiFunctionsApi.fetch();
      return response;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error as Error);
    }
  }
);

// Create a new AI function
export const createAiFunction = createAsyncThunk<
  AiFunction,
  Omit<AiFunction, 'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'>,
  { rejectValue: Error }
>('aiFunctions/createAiFunction', async (aiFunction, { rejectWithValue }) => {
  try {
    const response = await AiFunctionsApi.create(aiFunction);
    return response;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error as Error);
  }
});

// Update an existing AI function
export const updateAiFunction = createAsyncThunk<
  AiFunction,
  { id: number; payload: Partial<Omit<AiFunction, 'id'>> },
  { rejectValue: Error }
>('aiFunctions/updateAiFunction', async ({ id, payload }, { rejectWithValue }) => {
  try {
    const response = await AiFunctionsApi.patch({ id, payload });
    return response;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error as Error);
  }
});

// Delete an AI function
export const deleteAiFunction = createAsyncThunk<number, number, { rejectValue: Error }>(
  'aiFunctions/deleteAiFunction',
  async (id, { rejectWithValue }) => {
    try {
      await AiFunctionsApi.delete(id);
      return id; // Return the deleted ID so the reducer can remove it
    } catch (error) {
      console.error(error);
      return rejectWithValue(error as Error);
    }
  }
);
