import { cloneDeep } from 'lodash-es';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next';
import { Header, Icon, Loader } from 'semantic-ui-react';

import type { SenderEmail } from '@eeedo/types';
import type { TFunction, WithTranslation } from 'react-i18next';
import type { SemanticICONS } from 'semantic-ui-react';

import Comments from '../Comments/Comments';
import Collapsible from '../generic/Collapsible/Collapsible';
import ResizeDetectorContainer from '../generic/ResizeDetectorContainer';
import StickyWrapper from '../generic/Sticky/StickyWrapper';
import LoaderComponent from '../Loader/Loader';
import CaseContentEditor from './CaseContentEditor';
import CaseIFrameViewTabBar from './CaseIFrameViewTabBar';
import { CommentsContainerContext } from './CommentsContainerContext';
import { SHOULD_SHRINK_WIDTH } from './constants';
import FeatureFlags from 'src/api/FeatureFlags';
import { contentsVC } from 'src/api/VersionControl';
import TopBarGeneralInfo from 'src/Components/Case/TopBar/TopBarGeneralInfo';
import TopBarStatusButtons from 'src/Components/Case/TopBar/TopBarStatusButtons';
import DraftsContainer from 'src/containers/DraftsContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { getUrlSearchParam } from 'src/Utilities/helper';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';

import type { CaseHOCProps } from 'src/containers/CaseContainer';
import type { Ticket } from 'src/types/Ticket';

import './Case.css';

interface CaseProps extends CaseHOCProps, WithTranslation {
  task: Ticket;
  loading: boolean;
  iFrame: { url: string; activeTab: 'case' | 'iframe'; tabTitle: string } | undefined;
}

interface CaseState {
  isReplyHeaderPinned: boolean;
}

export class Case extends React.Component<CaseProps, CaseState> {
  static contextType = CommentsContainerContext;
  context: React.ContextType<typeof CommentsContainerContext>;

  private senderEmails: SenderEmail[];
  private replyContainer: React.RefObject<HTMLDivElement>;
  private replyHeaderRef = React.createRef<HTMLDivElement>();

  private taskStatusOptions: (t: TFunction) => {
    text: string;
    icon: SemanticICONS;
    value: string;
  }[] = (t) => [
    {
      text: t('CASE_STATUS_TODO'),
      icon: 'exclamation',
      value: 'todo'
    },
    {
      text: t('CASE_STATUS_DOING'),
      icon: 'clock',
      value: 'doing'
    },
    {
      text: t('CASE_STATUS_DONE'),
      icon: 'check',
      value: 'done'
    }
  ];

  constructor(props: CaseProps) {
    super(props);

    this.state = { isReplyHeaderPinned: false };

    this.senderEmails = [];
    this.replyContainer = React.createRef<HTMLDivElement>();
  }

  componentWillReceiveProps(nextProps: CaseProps) {
    if (nextProps.task) {
      if (nextProps.ticketTypes?.length > 0 && !nextProps.task.entityFields) {
        this.senderEmails = [];
        nextProps.ticketTypes.forEach((element) => {
          // wtf?!!
          const ticketType = cloneDeep(element);
          if (ticketType.senderemail) {
            ticketType.senderemail.type = ticketType.name;
            if (ticketType.name === nextProps.task.taskType) {
              ticketType.senderemail.default = true;
            }
            this.senderEmails.push(ticketType.senderemail);
          }
        });
      }
    }
  }

  componentDidMount() {
    this.getTask(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: CaseProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getTask(this.props.match.params.id);
    }
  }

  private getTask = (id: string) => {
    this.props.openTab(id);
    this.props.fetchTicket(id);
    contentsVC.loadDraft({
      taskId: taskIdToNumericalId(id),
      attachments: []
    });
  };

  private onCommentsContainerSizeChange = (width: number) => {
    const shouldShrink = width < SHOULD_SHRINK_WIDTH;
    const previous = this.context.shouldShrink;
    if (previous !== shouldShrink) {
      this.context.setShouldShrink(shouldShrink);
    }
  };

  caseView = () => {
    const { t } = this.props;
    const isNavigationHidden = !!getUrlSearchParam('hideNavigation');

    if (!this.props.task) {
      return <LoaderComponent />;
    }

    return (
      <ErrorBoundary>
        <div
          id={this.props.mobileMode ? 'mobileElement topelement' : 'topelement'}
          className={isNavigationHidden ? 'hiddenNavigation' : ''}
        >
          <Loader size="huge" active={this.props.loading}>
            {t('CASE_LOADING_CONTENT')}
          </Loader>
          <div
            className={this.props.mobileMode ? 'mobileElement detailViewScrollContainer' : 'detailViewScrollContainer'}
          >
            <TopBarGeneralInfo contentType="task" />

            <Scrollbars autoHide>
              <StickyWrapper className={this.props.mobileMode ? 'case-left-side-mobile' : 'case-left-side'}>
                <ResizeDetectorContainer onSizeChange={this.onCommentsContainerSizeChange}>
                  {this.props.mobileMode && (
                    <TopBarStatusButtons contentType="task" statusOptions={this.taskStatusOptions(t)} />
                  )}

                  {!FeatureFlags.isFlagOn('DISABLE_TASK_CONTENTS') && (
                    <div className="case-content-editor-wrapper">
                      {this.props.userData.permissions.includes('updateContent') && <CaseContentEditor />}
                    </div>
                  )}

                  <Comments
                    ticket={this.props.task}
                    senderEmails={this.senderEmails}
                    mobileMode={this.props.mobileMode}
                  />
                </ResizeDetectorContainer>
              </StickyWrapper>
            </Scrollbars>
            {this.props.userData.permissions.includes('updateContent') && (
              <Collapsible
                style={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: 'white',
                  boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.2)'
                }}
                heading={() => (
                  <Header
                    as="h3"
                    style={{
                      display: 'flex',
                      padding: '7px 0',
                      margin: 0
                    }}
                  >
                    <Icon name="talk" />
                    {t('CASE_ANSWER')}
                  </Header>
                )}
              >
                <div style={{ padding: '24px', borderTop: '1px solid rgba(17, 17, 17, 0.08)' }}>
                  <div ref={this.replyHeaderRef}></div>

                  <ErrorBoundary>
                    <div ref={this.replyContainer}>
                      <DraftsContainer />
                    </div>
                  </ErrorBoundary>
                </div>
              </Collapsible>
            )}
          </div>
        </div>
      </ErrorBoundary>
    );
  };

  render() {
    if (this.props.iFrame) {
      const taskId = taskIdToNumericalId(this.props.task?.id);

      return (
        <ErrorBoundary>
          <CaseIFrameViewTabBar
            caseTitle={this.props.task.id}
            frameTitle={`TSK${this.props.iFrame.tabTitle}`}
            taskId={taskId!}
            activeTab={this.props.iFrame.activeTab}
          />
          {this.props.iFrame.activeTab === 'case' ? (
            <this.caseView />
          ) : (
            <iframe src={this.props.iFrame.url} style={{ height: '84.5vh', width: '100%' }} frameBorder="0" />
          )}
        </ErrorBoundary>
      );
    }

    return <this.caseView />;
  }
}

export default withTranslation('translations')(Case);
