import { CSSProperties } from 'react';

const colors = {
  pdf: '#fb3748',
  image: '#7D52F4',
  excel: '#21C16B',
  other: 'grey'
};

const getColor = (extension: string) => {
  const key: keyof typeof colors =
    extension === 'PDF'
      ? 'pdf'
      : ['PNG', 'JPG', 'JPEG', 'GIF'].includes(extension)
      ? 'image'
      : ['XLS', 'XLSX'].includes(extension)
      ? 'excel'
      : 'other';

  return colors[key];
};

export const wrapper: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '50px',
  height: '50px'
};

export const extension: (extension: string) => CSSProperties = (extension) => ({
  position: 'absolute',
  top: '20px',
  left: 0,
  backgroundColor: getColor(extension),
  fontSize: '11px',
  padding: '0px 2.5px',
  borderRadius: '4px',
  color: 'white'
});
