import React from 'react';

import type { SenderEmail } from '@eeedo/types';
import type { FC } from 'react';

import ChannelType from '../CommentIconContent/ChannelType';
import AIFunctionButtons from './AIPromptButton';
import FeatureFlags from 'src/api/FeatureFlags';
import CommentEditorWidgetContainer from 'src/containers/CommentEditorWidgetContainer';
import ErrorBoundary from 'src/ErrorBoundary';

import type { CommentMetadata } from 'src/types/Ticket';

interface UserCommentActionButtonsProps {
  id: string;
  title: string | null;
  taskId: string;
  isHTML: boolean;
  created: number;
  channel: ChannelType | null;
  metaData: CommentMetadata | null;
  senderEmails: SenderEmail[];
  attachmentIds: string[];

  parsedContent?: string | null;
  isLastExternalComment?: boolean;
}

const UserCommentActionButtons: FC<UserCommentActionButtonsProps> = (props) => (
  <div className="userComment__actionButtons">
    {FeatureFlags.isFlagOn('ENABLE_OPENAI') && <AIFunctionButtons commentId={props.id} tinyButton basicButton />}

    {props.channel === ChannelType.Email && (
      <ErrorBoundary>
        <CommentEditorWidgetContainer
          senderEmails={props.senderEmails}
          created={props.created!}
          title={props.title || ''}
          comment={props.parsedContent ?? ''}
          taskId={props.taskId}
          metaData={props.metaData}
          isHTML={props.isHTML}
          attachmentIds={props.attachmentIds}
          buttonsType={props.isLastExternalComment ? 'primary' : 'basic'}
        />
      </ErrorBoundary>
    )}
  </div>
);

export default React.memo(UserCommentActionButtons);
