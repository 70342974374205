import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { createRef, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form } from 'semantic-ui-react';

import type { ComponentProps, CSSProperties, FC } from 'react';
import type { DropEvent, DropzoneRef } from 'react-dropzone';
import type { DropdownItemProps } from 'semantic-ui-react';

import { onDropRejected } from '../UploadErrorHandler';
import { MAX_ATTACHMENT_SIZE } from 'src/config';

import type { Attachment } from 'src/types/Ticket';

const dropdownStyles: CSSProperties = { fontSize: '15px', fontWeight: 600, color: '#707070' };

interface Props {
  attachments: Attachment[];
  options: DropdownItemProps[];
  value: string[] | string;
  isMultiple?: boolean;
  acceptExtensions?: {
    [key: string]: string[];
  };
  maxFileSize?: number;

  onDropAccepted: (files: File[], event: DropEvent) => void;
  onChangeAttachments: (attachments: string[]) => void;
}

const FormDropzoneDropdown: FC<Props> = ({
  attachments,
  options,
  acceptExtensions,
  maxFileSize,
  isMultiple = true,
  onChangeAttachments,
  onDropAccepted
}) => {
  const { t } = useTranslation();
  const dropzoneRef = createRef<DropzoneRef>();

  const onChange = useCallback<NonNullable<ComponentProps<typeof Dropdown>['onChange']>>(
    (_event, data) => {
      const val = data.value as string[] | string;
      if (val.toString().includes('ADD_ATTACHMENT')) {
        dropzoneRef.current?.open();
        if (Array.isArray(val)) val.pop();
      } else {
        const addedAttachments = attachments
          .filter((attachment) => val.includes(attachment.id))
          .map((attachment) => attachment.id);
        onChangeAttachments(addedAttachments);
      }
    },
    [attachments, dropzoneRef]
  );

  return (
    <Form.Input>
      <Dropzone
        maxSize={maxFileSize || MAX_ATTACHMENT_SIZE}
        multiple={isMultiple}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        ref={dropzoneRef}
        {...(!!acceptExtensions && { accept: acceptExtensions })}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className={classNames('AttachmentDropzone-hidden', isDragActive && 'AttachmentDropzone-Active')}
            {...getRootProps()}
          >
            <input name="attachments" {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      <div>
        <FontAwesomeIcon icon={faPaperclip} style={{ fontSize: '18px', marginRight: '6px' }} />
      </div>
      <div>
        <Dropdown
          style={dropdownStyles}
          options={options}
          icon={<></>}
          text={t('Attachments')}
          selectOnBlur={false}
          onChange={onChange}
        />
      </div>
    </Form.Input>
  );
};

export default React.memo(FormDropzoneDropdown);
