import type { CSSProperties } from 'react';

export const replyEditorStyle: CSSProperties = {
  borderRadius: '5px',
  marginTop: '10px',
  marginBottom: '10px',
  wordBreak: 'break-word'
};

export const form: CSSProperties = { marginTop: '20px' };

export const sendersLabel: CSSProperties = { color: '#888', fontWeight: 500, fontSize: '13px', lineHeight: '18px' };

export const sendersDropdown: CSSProperties = {
  color: '#414141',
  fontSize: '13px',
  fontWeight: 600,
  marginTop: '10px'
};

export const attachments: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end'
};
export const cannedResponse: CSSProperties = { display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' };

export const recipientsLabel: CSSProperties = { color: '#888', fontWeight: 500, fontSize: '13px' };
export const copyButtons: CSSProperties = { height: '31px' };

export const subjectError: CSSProperties = { color: 'red' };
