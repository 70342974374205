import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';

import type { FC } from 'react';

import * as styles from './SelectedAttachmen.style';
import FileIcon from 'src/Components/generic/FileIcon/FileIcon';
import Popup from 'src/Components/generic/Popup/Popup';

interface SelectedAttachmentProps {
  id: string;
  fileName: string;

  onClose(id: string): void;
}

const SelectedAttachment: FC<SelectedAttachmentProps> = ({ id, fileName, onClose }) => {
  const onClick = useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  return (
    <div style={styles.wrapper}>
      <FileIcon fileName={fileName} />
      <Popup trigger={<div style={styles.trigger}>{fileName}</div>} content={fileName} />
      <FontAwesomeIcon icon={faClose} size="lg" style={styles.icon} onClick={onClick} />
    </div>
  );
};

export default React.memo(SelectedAttachment);
