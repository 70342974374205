import React, { useMemo } from 'react';

import type { FC } from 'react';

import * as styles from './FileIcon.style';
import FileEmpty from 'src/assets/icons/FileEmpty';

const getImageExtension = (fileName: string): string => {
  const ext = fileName?.split('.').pop();
  return ext?.toLocaleUpperCase() ?? '';
};

interface FileIconProps {
  fileName: string;
}

const FileIcon: FC<FileIconProps> = ({ fileName }) => {
  const extension = getImageExtension(fileName);
  const extensionStyles = useMemo(() => styles.extension(extension), [extension]);

  return (
    <div style={styles.wrapper}>
      <FileEmpty />
      <div style={extensionStyles}>{extension}</div>
    </div>
  );
};

export default React.memo(FileIcon);
