import { Channels } from '@eeedo/types';
import {
  faCloud,
  faComments,
  faEnvelope,
  faLock,
  faMobileAlt,
  faNote,
  faPhone
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';

import type { SenderEmail, TicketType } from '@eeedo/types';
import type { CSSProperties } from 'react';
import type { TFunction } from 'react-i18next';
import type { SemanticShorthandItem, TabPaneProps } from 'semantic-ui-react';

import { DropzoneRefProvider } from '../Attachments/AttachmentsContext';
import ReplyChat from './ReplyChat';
import ReplyCustomerPortal from './ReplyCustomerPortal';
import ReplyD365 from './ReplyD365';
import ReplyEezy from './ReplyEezy';
import ReplyEmail from './ReplyEmail/ReplyEmail';
import ReplyFacebook from './ReplyFacebook';
import ReplyGiosg from './ReplyGiosg';
import ReplyInstagram from './ReplyInstagram';
import ReplyIntra from './ReplyIntra';
import ReplyPhone from './ReplyPhone';
import ReplySalesforce from './ReplySalesforce';
import ReplySecureEmail from './ReplySecureEmail';
import ReplySms from './ReplySms';
import ReplyTampuuri from './ReplyTampuuri';
import ReplyViestiTytti from './ReplyViestiTytti';
import ReplyWhatsapp from './ReplyWhatsapp';
import { hasPhoneIntegrations } from 'src/Utilities/phoneIntegrations';

import type { Draft } from 'src/reducers/draftsReducer';
import type { TicketWithActiveProperty } from 'src/reducers/ticketReducer';
import type { State } from 'src/types/initialState';
import type { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';

type Pane = {
  pane?: SemanticShorthandItem<TabPaneProps>;
  menuItem?: React.ReactNode;
  render?: () => React.ReactNode;
};
type Panes = { [p in Channels]: Pane };

interface GetReplyChannelPanesArgs {
  t: TFunction;
  mobileMode: boolean;
  canCloseAsDone: boolean;
  activeTicketType: TicketType;
  ticket: TicketWithActiveProperty;

  userData: State['userData'];
  ticketTypes: State['ticketTypes'];
  suggestions: State['suggestions'];
  users: State['usersList']['usersList'];
  drafts?: Draft;
  templates: State['templates']['responseTemplates'];
  shouldShrink?: boolean;

  addTag(tagId: string): any;
  // FIXME: all these functions are actually thunk actions, types might be improved
  // after refactoring all those actions to be return result of createThunk
  uploadFile(...args: any[]): any;
  setChatAnchor(...args: any[]): any;
  sendEntityEvent(...args: any[]): any;
  addOCCallRequest(...args: any[]): any;
  addRingCallRequest(...args: any[]): any;
  addMitelCallRequest(...args: any[]): any;
  getByFieldType(fieldType: string): string;
  onSubmit(body: { [key: string]: any }): any;
  updateGiosgWritingStatus(...args: any[]): any;
  updateChatTypingStatusToCustomer(...args: any[]): any;
  // these are thunk actions ↑
  updateStateToRedux(taskId: string, channel: Channels, state: any): any;
  getSenderEmails(taskType: string, ticketTypes: TicketType[]): SenderEmail[];
  changeTicketStatus(id: string, UID: string | undefined, status: TicketStatusAfterCommentSubmit): Promise<any>;
}

const iconStyle: CSSProperties = { marginRight: '6px', fontSize: '20px' };

export const getReplyChannelPanes = ({
  t,
  ticket,
  mobileMode,
  activeTicketType,
  users,
  drafts,
  userData,
  templates,
  suggestions,
  ticketTypes,
  shouldShrink,
  addTag,
  onSubmit,
  uploadFile,
  setChatAnchor,
  getByFieldType,
  canCloseAsDone,
  getSenderEmails,
  addOCCallRequest,
  addRingCallRequest,
  addMitelCallRequest,
  updateStateToRedux,
  changeTicketStatus,
  updateGiosgWritingStatus,
  updateChatTypingStatusToCustomer,
  sendEntityEvent
}: GetReplyChannelPanesArgs) => {
  const entityEventsEnabled = ticketTypes.find(
    (ticketType) => ticketType.name === ticket?.taskType
  )?.entityEventsEnabled;
  // FIXME: this logic is super weird
  // - why it's can be disabled for email and secure email, but always on for tampuuri?
  // - if this is a valid business logic why it is handled here, but not in appropriate components?
  const sendEntityEventWhenAllowed = entityEventsEnabled ? sendEntityEvent : undefined;

  const panes: Partial<Panes> = {
    [Channels.intra]: {
      menuItem: (
        <Menu.Item key="reply-intra" name="intra">
          <FontAwesomeIcon icon={faNote} style={iconStyle} />
          {mobileMode || t('REPLY_METHOD_INTRA')}
        </Menu.Item>
      ),
      render: () => (
        <ReplyIntra
          smallButtons={shouldShrink}
          task={ticket}
          taskId={ticket?.id}
          userData={userData}
          templates={templates}
          entities={ticket?.entities}
          ticketType={activeTicketType}
          drafts={drafts?.intra}
          onSubmit={onSubmit}
          updateState={updateStateToRedux}
        />
      )
    },
    [Channels.email]: {
      menuItem: (
        <Menu.Item key="reply-email" name="email">
          <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
          {mobileMode || t('REPLY_METHOD_EMAIL')}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyEmail
            smallButtons={shouldShrink}
            userData={userData}
            taskId={ticket.id}
            task={ticket}
            entities={ticket.entities}
            senderEmails={getSenderEmails(ticket.taskType, ticketTypes)}
            subject={ticket.title}
            templates={templates}
            ticketType={activeTicketType}
            taskType={activeTicketType}
            attachments={ticket.attachments}
            to={getByFieldType('emailField')}
            onSubmit={onSubmit}
            drafts={drafts?.email}
            updateState={updateStateToRedux}
            suggestions={suggestions}
            uploadFile={uploadFile}
            sendEntityEvent={sendEntityEventWhenAllowed}
            users={users}
            canCloseAsDone={canCloseAsDone}
            changeTicketStatus={changeTicketStatus}
            ticketTypes={ticketTypes}
          />
        );
      }
    },
    [Channels.chat]: {
      menuItem: (
        <Menu.Item key="reply-chat" name="chat">
          <FontAwesomeIcon icon={faComments} style={iconStyle} />
          {mobileMode || t('REPLY_METHOD_CHAT')}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyChat
            smallButtons={shouldShrink}
            userData={userData}
            taskId={ticket?.id}
            task={ticket}
            ticketType={activeTicketType}
            templates={templates}
            onSubmit={onSubmit}
            setChatAnchor={setChatAnchor}
            drafts={drafts?.chat}
            updateState={updateStateToRedux}
            entities={ticket?.entities}
            updateTypingStatusToCustomer={updateChatTypingStatusToCustomer}
            canCloseAsDone={canCloseAsDone}
            changeTicketStatus={changeTicketStatus}
            ticketTypes={ticketTypes}
          />
        );
      }
    },
    [Channels.sms]: {
      menuItem: (
        <Menu.Item key="reply-sms" name="sms">
          <FontAwesomeIcon icon={faMobileAlt} style={iconStyle} />
          {mobileMode || t('REPLY_METHOD_SMS')}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplySms
            smallButtons={shouldShrink}
            task={ticket}
            taskId={ticket.id}
            userData={userData}
            templates={templates}
            suggestions={suggestions}
            entities={ticket.entities}
            ticketType={activeTicketType}
            drafts={drafts?.sms}
            phoneNumbers={getByFieldType('phoneField')}
            onSubmit={onSubmit}
            updateState={updateStateToRedux}
          />
        );
      }
    },
    [Channels.secureMail]: {
      menuItem: (
        <Menu.Item key="reply-secureMail" name="secureMail">
          <FontAwesomeIcon icon={faLock} style={iconStyle} />
          {mobileMode || t('REPLY_METHOD_SECURE_MAIL')}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplySecureEmail
            smallButtons={shouldShrink}
            userData={userData}
            taskId={ticket.id}
            task={ticket}
            entities={ticket.entities}
            senderEmails={getSenderEmails(ticket.taskType, ticketTypes)}
            subject={ticket.title}
            templates={templates}
            ticketType={activeTicketType}
            attachments={ticket.attachments}
            to={getByFieldType('emailField')}
            onSubmit={onSubmit}
            /**
             * Desc: Secure email and email should share the state
             */
            drafts={drafts?.email}
            updateState={updateStateToRedux}
            suggestions={suggestions}
            uploadFile={uploadFile}
            sendEntityEvent={sendEntityEventWhenAllowed}
            users={users}
            canCloseAsDone={canCloseAsDone}
            changeTicketStatus={changeTicketStatus}
            taskType={activeTicketType}
            ticketTypes={ticketTypes}
          />
        );
      }
    },
    [Channels.facebook]: {
      menuItem: (
        <Menu.Item key="reply-facebook" name="facebook">
          <Icon name="facebook messenger" style={iconStyle} />
          {mobileMode || t('REPLY_METHOD_FACEBOOK')}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyFacebook
            smallButtons={shouldShrink}
            task={ticket}
            taskId={ticket.id}
            userData={userData}
            templates={templates}
            entities={ticket.entities}
            ticketType={activeTicketType}
            drafts={drafts?.facebook}
            onSubmit={onSubmit}
            updateState={updateStateToRedux}
          />
        );
      }
    },
    [Channels.giosg]: {
      menuItem: (
        <Menu.Item key="reply-giosg" name="giosg">
          <FontAwesomeIcon icon={faComments} style={iconStyle} />
          {mobileMode || 'Giosg'}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyGiosg
            smallButtons={shouldShrink}
            users={users}
            task={ticket}
            taskId={ticket.id}
            userData={userData}
            templates={templates}
            ticketTypes={ticketTypes}
            entities={ticket.entities}
            ticketType={activeTicketType}
            canCloseAsDone={canCloseAsDone}
            drafts={drafts?.giosg}
            onSubmit={onSubmit}
            updateState={updateStateToRedux}
            switchChatAnchor={setChatAnchor}
            changeTicketStatus={changeTicketStatus}
            updateGiosgWritingStatus={updateGiosgWritingStatus}
          />
        );
      }
    },
    [Channels.customerPortal]: {
      menuItem: (
        <Menu.Item key="reply-customerPortal" name="customerPortal">
          <FontAwesomeIcon icon={faComments} style={iconStyle} />
          {mobileMode || t('REPLY_METHOD_CUSTOMER_PORTAL')}
        </Menu.Item>
      ),
      render: () => {
        return (
          <DropzoneRefProvider>
            <ReplyCustomerPortal
              smallButtons={shouldShrink}
              addTag={addTag}
              userData={userData}
              activeTicketType={activeTicketType}
              taskId={ticket.id}
              task={ticket}
              entities={ticket.entities}
              subject={ticket.title}
              templates={templates}
              attachments={ticket.attachments}
              onSubmit={onSubmit}
              drafts={drafts?.customerPortal}
              updateState={updateStateToRedux}
            />
          </DropzoneRefProvider>
        );
      }
    },
    [Channels.tampuuri]: {
      menuItem: (
        <Menu.Item key="reply-tampuuri" name="tampuuri">
          <FontAwesomeIcon icon={faComments} style={iconStyle} />
          {mobileMode || 'Tampuuri'}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyTampuuri
            smallButtons={shouldShrink}
            userData={userData}
            taskId={ticket.id}
            task={ticket}
            entities={ticket.entities}
            subject={ticket.title}
            templates={templates}
            ticketType={activeTicketType}
            attachments={ticket.attachments}
            to={getByFieldType('tampuuriField')}
            onSubmit={onSubmit}
            drafts={drafts?.tampuuri}
            updateState={updateStateToRedux}
            suggestions={suggestions}
            uploadFile={uploadFile}
            sendEntityEvent={sendEntityEvent}
            users={users}
          />
        );
      }
    },
    [Channels.whatsapp]: {
      menuItem: (
        <Menu.Item key="reply-whatsapp" name="whatsapp">
          <Icon name="whatsapp" style={iconStyle} />
          {mobileMode || 'WhatsApp'}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyWhatsapp
            smallButtons={shouldShrink}
            task={ticket}
            users={users}
            taskId={ticket.id}
            userData={userData}
            templates={templates}
            entities={ticket.entities}
            ticketType={activeTicketType}
            attachments={ticket.attachments}
            drafts={drafts?.whatsapp}
            onSubmit={onSubmit}
            uploadFile={uploadFile}
            updateState={updateStateToRedux}
            switchChatAnchor={setChatAnchor}
          />
        );
      }
    },
    [Channels.salesforce]: {
      menuItem: (
        <Menu.Item key="reply-salesforce" name="salesforce">
          <FontAwesomeIcon icon={faCloud} style={iconStyle} />
          {mobileMode || 'Salesforce'}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplySalesforce
            smallButtons={shouldShrink}
            task={ticket}
            taskId={ticket.id}
            userData={userData}
            fieldSet={activeTicketType.fieldSets.find((fs) => fs.id === 'customerInfo')}
            drafts={drafts?.salesforce}
            updateState={updateStateToRedux}
          />
        );
      }
    },
    [Channels.d365]: {
      menuItem: (
        <Menu.Item key="reply-d365" name="d365">
          <Icon name="microsoft" style={iconStyle} />
          {mobileMode || 'D365'}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyD365
            smallButtons={shouldShrink}
            task={ticket}
            taskId={ticket.id}
            userData={userData}
            drafts={drafts?.d365}
            updateState={updateStateToRedux}
          />
        );
      }
    },
    [Channels.eezy]: {
      menuItem: (
        <Menu.Item key="reply-eezy" name="eezy">
          <FontAwesomeIcon icon={faCloud} style={iconStyle} />
          {mobileMode || 'Eezy'}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyEezy
            smallButtons={shouldShrink}
            users={users}
            task={ticket}
            taskId={ticket.id}
            userData={userData}
            templates={templates}
            entities={ticket.entities}
            ticketType={activeTicketType}
            attachments={ticket.attachments}
            drafts={drafts?.eezy}
            uploadFile={uploadFile}
            updateState={updateStateToRedux}
          />
        );
      }
    },
    [Channels.viestitytti]: {
      menuItem: (
        <Menu.Item key="reply-viestitytti" name="viestitytti">
          <FontAwesomeIcon icon={faCloud} style={iconStyle} />
          {mobileMode || 'Viesti-Tytti'}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyViestiTytti
            smallButtons={shouldShrink}
            task={ticket}
            users={users}
            taskId={ticket.id}
            userData={userData}
            templates={templates}
            entities={ticket.entities}
            ticketType={activeTicketType}
            drafts={drafts?.viestitytti}
            updateState={updateStateToRedux}
          />
        );
      }
    },
    [Channels.instagram]: {
      menuItem: (
        <Menu.Item key="reply-instagram" name="instagram">
          <Icon name="instagram" style={iconStyle} />
          {mobileMode || t('REPLY_METHOD_INSTAGRAM')}
        </Menu.Item>
      ),
      render: () => {
        return (
          <ReplyInstagram
            smallButtons={shouldShrink}
            task={ticket}
            taskId={ticket.id}
            userData={userData}
            templates={templates}
            entities={ticket.entities}
            ticketType={activeTicketType}
            attachments={ticket.attachments}
            drafts={drafts?.instagram}
            uploadFile={uploadFile}
            updateState={updateStateToRedux}
          />
        );
      }
    }
  };

  const { hasElisaOCIntegration, hasElisaRingIntegration, hasMitelIntegration, hasEnreachVoiceIntegration } =
    hasPhoneIntegrations();

  if (hasElisaOCIntegration || hasElisaRingIntegration || hasMitelIntegration || hasEnreachVoiceIntegration) {
    panes[Channels.phone] = {
      menuItem: (
        <Menu.Item key="reply-phone" name="phone">
          <FontAwesomeIcon icon={faPhone} style={iconStyle} />
          {mobileMode || 'Phone'}
        </Menu.Item>
      ),
      render: () => (
        <ReplyPhone
          smallButtons={shouldShrink}
          userData={userData}
          taskId={ticket.id}
          task={ticket}
          entities={ticket.entities}
          phoneNumbers={getByFieldType('phoneField')}
          ticketType={activeTicketType}
          templates={templates}
          onSubmit={onSubmit}
          drafts={drafts?.phone}
          updateState={updateStateToRedux}
          suggestions={suggestions}
          ticketTypes={ticketTypes}
          addRingCallRequest={addRingCallRequest}
          addOCCallRequest={addOCCallRequest}
          addMitelCallRequest={addMitelCallRequest}
        />
      )
    };
  }

  return panes;
};
